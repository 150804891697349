import React from 'react'
import { Button, Menu, Popup } from '../../components'
import { useSelector, useDispatch } from 'react-redux'
import fixNum from '../Func.wallet/fixNum'
import { ItemSelectAccounts } from '../../components'
import { setDeleteWallet, resetWallet } from '../../redux/slices/StorageSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { setDataWallet, setDeleteName } from '../../redux/slices/WalletSlice'
import styles from './account.module.css'
import Svg from '../../svgs/Svg'
import cn from 'classnames'
import { Header } from '../../components/Header/Header';

export const AccountWallets = () => {
	const { state } = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { dataUser, currentWallet, usePin, backup } = useSelector(
		(state) => state.storage
	)
	const {walletAddress, portfolioBalanceUsd, deleteName } = useSelector(
		(state) => state.wallet
	)
	const [openDelete, setOpenDelete] = React.useState(false)
	const [openDeleteWarning, setOpenDeleteWarning] = React.useState(false)
	const [openDeleteBackup, setOpenDeleteBackup] = React.useState(false)
	const [openDeleteBackupWarning, setOpenDeleteBackupWarning] =
		React.useState(false)
		
	const onDeleteWallet = (backup, name) => {
		dispatch(setDeleteName(name))
		if (backup) {
			setOpenDelete(true)
		} else {
			setOpenDeleteBackup(true)
		}
	}

	React.useEffect(() => {
		if (state !== null) {
			if (backup) {
				setOpenDeleteWarning(true)
			} else {
				setOpenDeleteBackupWarning(true)
			}
		}
	}, [state])

	const onDeleteWalletWarning = (modal) => {
		if (modal) {
			setOpenDelete(false)
			if (usePin) {
				navigate('/login-pin', { state: { to: '/wallets' } })
			} else {
				setOpenDeleteWarning(true)
			}
		} else {
			setOpenDeleteBackup(false)
			if (usePin) {
				navigate('/login-pin', { state: { to: '/wallets' } })
			} else {
				setOpenDeleteBackupWarning(true)
			}
		}
	}

	const onDeleteAccount = () => {
		navigate('/')
		dispatch(resetWallet())
	}

	const goKeyOrPhrase = (to) => {
		if (usePin) {
			navigate('/login-pin', { state: { to: to } })
		} else {
			navigate(to)
		}
	}

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Wallets' />
				<div className={styles.cardInfo}>
					<p>{walletAddress.slice(0, 27) + "..." + walletAddress.slice(-6)}</p>
					<span>$ {fixNum(portfolioBalanceUsd)}</span>
				</div>
				<button className={styles.buttonBlack} onClick={() => goKeyOrPhrase('/copy-phrase')}>
					<Svg type='stats'/>
					<span>View Recovery Phrase</span>
				</button>
				<button className={styles.buttonBlack} onClick={() => goKeyOrPhrase('/copy-key')}>
					<Svg type='key'/>
					<span>View Private Key</span>
				</button>
				<button onClick={() => onDeleteWallet(currentWallet)} className={cn(styles.buttonBlack, styles.redColor)}>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M3.67241 6.00408C4.0844 5.96123 4.45312 6.26048 4.49597 6.67247L5.83974 19.5919C5.86205 19.7727 5.94946 19.9394 6.08571 20.0605C6.22301 20.1826 6.40035 20.2501 6.58408 20.2501H17.4164C17.6001 20.2501 17.7775 20.1826 17.9148 20.0605C18.051 19.9394 18.1384 19.7728 18.1607 19.5919L19.504 6.6725C19.5469 6.2605 19.9156 5.96124 20.3276 6.00408C20.7396 6.04692 21.0388 6.41563 20.996 6.82762L19.6521 19.7529C19.6517 19.7563 19.6513 19.7597 19.651 19.763C19.5865 20.3104 19.3234 20.8152 18.9115 21.1814C18.4996 21.5477 17.9676 21.75 17.4164 21.7501H6.58404C6.03285 21.75 5.50084 21.5477 5.08895 21.1814C4.67705 20.8152 4.41393 20.3104 4.34951 19.763C4.34921 19.7604 4.34891 19.7578 4.34864 19.7552L4.3484 19.753L3.00402 6.82765C2.96117 6.41566 3.26042 6.04694 3.67241 6.00408Z" fill="#C83E2E"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M0.75 3.75C0.75 2.92157 1.42157 2.25 2.25 2.25H21.75C22.5784 2.25 23.25 2.92157 23.25 3.75V6C23.25 6.82843 22.5784 7.5 21.75 7.5H2.25C1.42157 7.5 0.75 6.82843 0.75 6V3.75ZM21.75 3.75H2.25V6H21.75V3.75Z" fill="#C83E2E"/>
						<path d="M8.5 1.75C8.5 1.05964 9.05964 0.5 9.75 0.5H14.25C14.9404 0.5 15.5 1.05964 15.5 1.75C15.5 2.44036 14.9404 3 14.25 3H9.75C9.05964 3 8.5 2.44036 8.5 1.75Z" stroke="#C83E2E"/>
					</svg>
					<span>Delete wallet</span>
				</button>
			</div>
			<Popup
				isOpen={openDeleteBackup}
				title='Delete wallet'
				text='To delete your wallet, you mast perform a manual backup.'>
				<Button variant='default' onClick={() => onDeleteWalletWarning(false)}>
					Okay
				</Button>
				<Button variant='inline' onClick={() => setOpenDeleteBackup(false)}>
					Cancel
				</Button>
			</Popup>
			<Popup
				isOpen={openDelete}
				title='Delete wallet'
				text='Deleting this wallet will erase your current wallet.'>
				<Button
					variant='default'
					colorText='var(--red)'
					onClick={() => onDeleteWalletWarning(true)}>
					Delete
				</Button>
				<Button variant='inline' onClick={() => setOpenDelete(false)}>
					Cancel
				</Button>
			</Popup>
			<Popup
				isOpen={openDeleteWarning}
				type='warning'
				title='Warning'
				titleTextStyled
				text='Are you sure you want to delete the wallet? Please, make sure you saved the phrase. Funds associated with the wallet will not be affected.'>
				<Button
					variant='default'
					colorText='var(--red)'
					onClick={onDeleteAccount}>
					Delete
				</Button>
				<Button variant='inline' onClick={() => setOpenDeleteWarning(false)}>
					Cancel
				</Button>
			</Popup>
			<Popup
				isOpen={openDeleteBackupWarning}
				type='warning'
				title='Warning'
				titleTextStyled
				text='Without the manual backup of your current wallet, you will lose access to its funds forever.'>
				<Button
					variant='default'
					colorText='var(--red)'
					onClick={onDeleteAccount}>
					Delete
				</Button>
				<Button
					variant='inline'
					onClick={() => setOpenDeleteBackupWarning(false)}>
					Cancel
				</Button>
			</Popup>
		</div>
	)
}
